import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { Navbar, Nav, Image } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import TP from "./TPdetector_logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    padding: "0 20px",
    boxSizing: "border-box",
  },
  form: {
    display: "flex ",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "400px",
  },
  textField: {
    width: "100%",

    marginBottom: theme.spacing(2),
  },
  button: {
    width: "23%",
    fontSize: "0.8rem",
    marginTop: theme.spacing(2),
  },
}));

function Login() {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const loginData = {
      email: email,
      password: password,
    };
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + "/login", loginData);

      const { token } = response.data;

      console.log(response.data);

      localStorage.setItem("token", token);
      setEmail("");
      setPassword("");

      axios
        .get(process.env.REACT_APP_API_URL + "/user", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const data = response.data.data;
          swal("Success", "Successfully logged in", "success");
          localStorage.setItem("user_id", data._id);
          localStorage.setItem("user_name", data.name);
          localStorage.setItem("user_email", data.email);
          localStorage.setItem("user_role", data.role);
          localStorage.setItem("word_count", data.wordCount);
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 2000);
        });
    } catch (error) {
      swal(error.response.data.message);
    }
  };

  const containerStyle = {
    textAlign: "center",
    padding: "20px",
  };

  const headingStyle = {
    fontSize: "2rem",
    margin: "20px 0",
  };
  const payTableTd = {
    padding: ".2rem 1rem",
    border: "1px solid #0006",
  };
  const payStyle = {
    fontSize: "1.5rem",
    fontWeight: "bold",
  };

  const priceStyle = {
    fontSize: "1rem",
  };

  const linkStyle = {
    backgroundColor: "#007bff",
    color: "white",
    padding: "10px 20px",
    borderRadius: "5px",
    textDecoration: "none",
    width: "300px",
    marginInline: "1rem",
  };

  const styles = {
    body: {
      fontFamily: '"Poppins", sans-serif',
      margin: 0,
      padding: 0,
    },
    invoiceForm: {
      fontFamily: '"Poppins", sans-serif',
    },
    popupContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      fontSize: "0.9rem",
      zIndex: 100,
    },
    popup: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.43)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 100,
      overflowY: "auto",
    },
    popupContent: {
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "8px",
      width: "90%",
      maxWidth: "980px",
      marginBlock: "2rem",
    },
    close: {
      position: "absolute",
      top: "10px",
      right: "10px",
      fontSize: "24px",
      color: "#888",
      cursor: "pointer",
      borderRadius: "20%",
      padding: "7px",
    },
    closeHover: {
      color: "#333",
    },
    h2: {
      marginBottom: "20px",
    },
    formGroup: {
      marginBottom: "20px",
    },
    label: {
      display: "block",
      marginBottom: "5px",
    },
    input: {
      width: "100%",
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      transition: "border-color 0.3s ease",
      boxSizing: "border-box",
    },
    inputFocus: {
      borderColor: "#007bff",
    },
    registrationFeeInput: {
      display: "flex",
      alignItems: "center",
    },
    registrationFeeInputSelect: {
      marginLeft: "10px",
      padding: "9px",
    },
    button: {
      padding: "10px 20px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
      margin: "auto",
      display: "table",
    },
    buttonHover: {
      backgroundColor: "#0056b3",
      margin: "auto",
      display: "table",
    },
    currencyContainer: {
      display: "flex",
      justifyContent: "space-around",
    },
    currencyRadio: {
      display: "inline-block",
    },
    currencyLabel: {
      display: "inline-block",
      marginLeft: "5px",
    },
    mediaQuery: {
      "@media (max-width: 991px)": {
        popup: {
          // display: 'table',
        },
        popupContent: {
          margin: "auto",
        },
      },
    },
  };

  const navBarStyle = {
    background: "#D9FDFF",
    color: "#000000",
    padding: "10px",
  };

  const navbarStyle = {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "light",
    color: "black",
    padding: "10px",
  };

  return (
    <div>
      <Navbar expand="lg" style={navbarStyle}>
        <Container>
          <Navbar.Brand href="./">
            <Image src={TP} alt="TP Detector Logo" style={{ width: "70px", height: "auto" }} className="display-3" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto me-2">
              <Nav.Link href="./" className="">
                Home
              </Nav.Link>
              <Nav.Link href="./SignUp" className="">
                Sign-Up
              </Nav.Link>
              <Nav.Link href="/Login" className="gx-5">
                Login
              </Nav.Link>
              <Nav.Link href="/SubmitPaper" className="gx-5">
                Submit Paper
              </Nav.Link>

              <Nav.Link href="/Contact" className="gx-5">
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            className={classes.textField}
          />
          <br />
          <TextField
            id="password"
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            className={classes.textField}
          />
          <br />
          <span className="border border p-1 rounded">
            {" "}
            Don't have an account? &nbsp;
            <Button href="/SignUp" className="bg-success text-white p-1">
              SignUp here
            </Button>
          </span>
          <br />
          <Button type="submit" variant="contained" color="primary" className={classes.button}>
            Login
          </Button>{" "}
          <br />
        </form>
      </div>
    </div>
  );
}

export default Login;
