import react from "react";
import { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Navbar, Nav, Image } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import axios from "axios";
import Swal from "sweetalert2";
import image from "./lens.webp";
import TP from "./TPdetector_logo.png";
import Right from "./Right-img.png";
import Img1 from "./20638.jpg";
import Img2 from "./Img-2.jpg";
import Img3 from "./Img-3.jpg";
import Img4 from "./Img4.jpg";
import Ejesra from "./eJESRA-icon.png";
import Loader from "./Loader.gif";

import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import "./InvoiceGen.css";

const Home = () => {
  const styles = {
    body: {
      fontFamily: '"Poppins", sans-serif',
      margin: 0,
      padding: 0,
    },
    invoiceForm: {
      fontFamily: '"Poppins", sans-serif',
    },
    popupContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      fontSize: "0.9rem",
      zIndex: 100,
    },
    popup: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.43)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 100,
      overflowY: "auto",
    },
    popupContent: {
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "8px",
      width: "90%",
      maxWidth: "980px",
      marginBlock: "2rem",
    },
    close: {
      position: "absolute",
      top: "10px",
      right: "10px",
      fontSize: "24px",
      color: "#888",
      cursor: "pointer",
      borderRadius: "20%",
      padding: "7px",
    },
    closeHover: {
      color: "#333",
    },
    h2: {
      marginBottom: "20px",
    },
    formGroup: {
      marginBottom: "20px",
    },
    label: {
      display: "block",
      marginBottom: "5px",
    },
    input: {
      width: "100%",
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      transition: "border-color 0.3s ease",
      boxSizing: "border-box",
    },
    inputFocus: {
      borderColor: "#007bff",
    },
    registrationFeeInput: {
      display: "flex",
      alignItems: "center",
    },
    registrationFeeInputSelect: {
      marginLeft: "10px",
      padding: "9px",
    },
    button: {
      padding: "10px 20px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
      margin: "auto",
      display: "table",
    },
    buttonHover: {
      backgroundColor: "#0056b3",
      margin: "auto",
      display: "table",
    },
    currencyContainer: {
      display: "flex",
      justifyContent: "space-around",
    },
    currencyRadio: {
      display: "inline-block",
    },
    currencyLabel: {
      display: "inline-block",
      marginLeft: "5px",
    },
    mediaQuery: {
      "@media (max-width: 991px)": {
        popup: {
          // display: 'table',
        },
        popupContent: {
          margin: "auto",
        },
      },
    },
  };

  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.src = "https://counter8.optistats.ovh/private/counter.js?c=9h1ylcej2mamhmcwhm3ya6jxsspgx1zd&down=async";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const Handle_Submit = () => {
    window.open("https://ejesra.com/", "_blank");
  };

  const [count, setCount] = useState(0);
  useEffect(() => {
    const storedCount = localStorage.getItem("pageVisits");
    const initialCount = Number(storedCount) || 0;
    setCount(initialCount + 1);
    localStorage.setItem("pageVisits", initialCount + 1);
  }, []);
  const countDigits = count.toString().split("").map(Number);

  const navBarStyle = {
    background: "#D9FDFF",
    color: "#000000",
    padding: "10px",
  };

  const navbarStyle = {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "light",
    color: "black",
    padding: "10px",
  };

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "Test",
    email: "test@example.com",
    phone: "9087654321",
    billAddress: "NIL",
    shipAddress: "NIL",
    numberOfWords: "10",
    title: "Testing Title",
    registrationFee: "",
    currency: "",
  });

  const togglePopup = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "currency") {
      if (value === "INR") {
        document.getElementById("registrationFee").innerHTML = `
        <option selected disabled value="">Select Currency & Amount</option>`;

        for (let i = 1; i <= 100; i++) {
          const el = document.createElement("option");
          el.value = `${i}00`;
          el.innerHTML = `&#8377;${i}00 - ${i * 5},000 Words`;
          document.getElementById("registrationFee").appendChild(el);
        }
      } else if (value === "USD") {
        document.getElementById("registrationFee").innerHTML = `<option selected disabled value="">Select Currency & Amount</option>`;

        for (let i = 1; i <= 100; i++) {
          const el = document.createElement("option");
          el.value = `${i}`;
          el.innerHTML = `$${i} - ${i * 5},000 Words`;
          document.getElementById("registrationFee").appendChild(el);
        }
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validatePhoneNumber = (phone) => {
    const re = /^(\+\d{1,3}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    return re.test(phone);
  };

  const validateForm = () => {
    // Validation logic for all fields
    if (!formData.name || formData.name.length > 25) {
      alert("Please enter a name with less than 25 characters.");
      return false;
    }
    if (!validateEmail(formData.email)) {
      alert("Please enter a valid email address.");
      return false;
    }
    if (!validatePhoneNumber(formData.phone)) {
      alert("Please enter a valid phone number.");
      return false;
    }
    if (
      !formData.billAddress ||
      formData.billAddress.split(" ").length > 50 ||
      !formData.shipAddress ||
      formData.shipAddress.split(" ").length > 50
    ) {
      alert("Billing and shipping addresses must be provided and less than 50 words each.");
      return false;
    }
    if (!formData.numberOfWords || formData.numberOfWords.length > 6) {
      alert("Number of words must be provided and less than 6 digits.");
      return false;
    }
    if (!formData.title || formData.title.split(" ").length > 25) {
      alert("Title must be provided and less than 25 words.");
      return false;
    }
    if (!formData.currency || !formData.registrationFee) {
      alert("Please select currency and provide registration fee.");
      return false;
    }
    return true;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });

    if (!validateForm()) return false;
    setLoading(true);
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + "/invoicegenerator", formData, { responseType: "blob" });
      console.log("Form date", formData);

      setLoading(false);

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      console.log("Form data sent successfully");
      Swal.fire({ text: "Form submitted successfully", icon: "success" });

      setTimeout(() => {
        Toast.fire({
          icon: "success",
          title: "Invoice downloaded successfully",
        });

        setTimeout(() => {
          const a = document.createElement("a");
          a.href = url;
          a.download = `Invoice.pdf`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }, 600);
      }, 1500);
    } catch (error) {
      setLoading(false);

      console.error("Error sending form data:", error.message);
      alert("Error submitting form. See console for details.");
    }

    // Reset form data
    setFormData({
      name: "",
      email: "",
      phone: "",
      billAddress: "",
      shipAddress: "",
      numberOfWords: "",
      title: "",
      registrationFee: "",
      currency: "",
    });

    // document.getElementById("registrationFee").innerHTML = `<option selected disabled value="">Select Currency & Amount</option>`;

    const registrationFeeSelect = document.getElementById("registrationFee");
    if (registrationFeeSelect) {
      registrationFeeSelect.innerHTML = `<option selected disabled value="">Select Currency & Amount</option>`;
    } else {
      console.error("Element with id 'registrationFee' not found in the DOM");
    }
  };

  {
    /* _______________________________________________________________________________________________________________________________________________ */
  }

  return (
    <div>
      <div style={navBarStyle}>
        <div className="container">
          {" "}
          <br />
          <Navbar expand="lg" style={navbarStyle}>
            <Container>
              <Navbar.Brand href="./">
                <Image src={TP} alt="TP Detector Logo" style={{ width: "70px", height: "auto" }} className="display-3" />
              </Navbar.Brand>

              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto me-2">
                  <Nav.Link href="./SignUp" className="">
                    Sign-Up
                  </Nav.Link>
                  <Nav.Link href="/Login" className="gx-5">
                    Login
                  </Nav.Link>
                  <Nav.Link href="/SubmitPaper" className="gx-5">
                    Submit Paper
                  </Nav.Link>

                  <Nav.Link href="/Contact" className="gx-5">
                    Contact Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <h1 className="text-center" style={{ fontSize: "50px" }}>
            TP Detector
          </h1>
          <div>
            <h5 style={{ textShadow: "0.5px 0.5px", fontWeight: "bold" }} className="text-left">
              &nbsp; Human or AI?
            </h5>

            <div className="container mt-3">
              <div className="row">
                <div className="col-lg-10 text-left">
                  <p>
                    {" "}
                    <Image src={Right} alt="TP Detector Logo" style={{ width: "25px", height: "auto" }} className="display-3" />
                    &nbsp; Know the Originality and Authenticity of any Research
                  </p>
                  <p>
                    {" "}
                    <Image src={Right} alt="TP Detector Logo" style={{ width: "25px", height: "auto" }} className="display-3" />
                    &nbsp; TP Detector is a reliable tool to ensure the content's originality, written by a human and AI-generated.
                  </p>
                  <p>
                    {" "}
                    <Image src={Right} alt="TP Detector Logo" style={{ width: "25px", height: "auto" }} className="display-3" />
                    &nbsp; Detect the new Tortured Phrases (TP), Copied and Synthetic Images, and Language Quality in a research paper.
                  </p>
                </div>

                <div className="col-lg-2">
                  <div className="text-center bg-warning mx-md-auto d-table p-3 rounded">
                    <p
                      style={{
                        marginBottom: "10px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Powered by
                    </p>
                    <a onClick={Handle_Submit} target="_blank" style={{ cursor: "pointer", display: "inline-block" }}>
                      <img src={Ejesra} alt="Ejesra Logo" style={{ width: "150px", height: "auto" }} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-left mt-4 gap-4">
              <Button
                className="border border-light text-dark p-3  rounded"
                style={{
                  backgroundColor: "lightgreen",
                  textShadow: "0.5px 0.5px",
                }}
                href="/SubmitPaper"
              >
                Check Your Article
              </Button>

              {/* invoice popup */}
              {/* _______________________________________________________________________________________________________________________________________________ */}
              <button
                onClick={togglePopup}
                className="border border-light text-dark p-3 ml-3 rounded"
                style={{
                  backgroundColor: "#FFA756",
                  fontWeight: "bold",
                }}
              >
                Request Invoice
              </button>
            </div>

            {isOpen && (
              <div className="popup-container invoice-form">
                <div className="popup ">
                  <div className="popup-content">
                    {loading ? (
                      //  {true ? (

                      <div style={{ margin: "auto", display: "table" }}>
                        <Image src={Loader} alt="TP Detector Logo" style={{ width: "70px", height: "auto" }} />
                      </div>
                    ) : (
                      <div className="invoice-form-container">
                        <form onSubmit={handleSubmit} className="">
                          <button className="close" onClick={togglePopup}>
                            &times;
                          </button>
                          <div className="d-flex justify-content-between">
                            <h2>Fill in the Details</h2>
                            <p>
                              Required fields <span className="text-danger ">&#42;</span>
                            </p>
                          </div>
                          <div className="row">
                            <div className="form-group col-lg-5">
                              <label htmlFor="name">
                                Name:<span className="text-danger ">&#42;</span>
                              </label>
                              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                            </div>
                            <div className="form-group col-lg-4">
                              <label htmlFor="email">
                                Email:<span className="text-danger ">&#42;</span>
                              </label>
                              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                            </div>
                            <div className="form-group col-lg-3">
                              <label htmlFor="phone">
                                Phone Number:<span className="text-danger ">&#42;</span>
                              </label>
                              <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-lg-6">
                              <label htmlFor="billAddress">
                                Billing Address:
                                <span className="text-danger ">&#42;</span>
                              </label>
                              <textarea id="billAddress" name="billAddress" value={formData.billAddress} onChange={handleChange} required />
                            </div>
                            <div className="form-group col-lg-6">
                              <label htmlFor="shipAddress">
                                Shipping Address:
                                <span className="text-danger ">&#42;</span>
                              </label>
                              <textarea id="shipAddress" name="shipAddress" value={formData.shipAddress} onChange={handleChange} required />
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group col-lg-3">
                              <label htmlFor="numberOfWords">
                                Number of Words:
                                <span className="text-danger ">&#42;</span>
                              </label>
                              <input
                                type="number"
                                id="numberOfWords"
                                name="numberOfWords"
                                value={formData.numberOfWords}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-group col-lg-9">
                              <label htmlFor="title">
                                Enter the Title:
                                <span className="text-danger ">&#42;</span>
                              </label>
                              <input type="text" id="title" name="title" value={formData.title} onChange={handleChange} required />
                            </div>
                          </div>

                          <label htmlFor="">
                            Select Amount:<span className="text-danger ">&#42;</span>
                          </label>

                          <div className="registration-container row">
                            <div className="currency-container my-3 col-lg-5 order-lg-2">
                              <div>
                                <input
                                  type="radio"
                                  id="usd"
                                  name="currency"
                                  value="USD"
                                  checked={formData.currency === "USD"}
                                  onChange={handleChange}
                                  className="currency-radio"
                                />
                                <label htmlFor="usd" className="currency-label ps-2">
                                  USD
                                </label>
                              </div>

                              <div>
                                <input
                                  type="radio"
                                  id="inr"
                                  name="currency"
                                  value="INR"
                                  checked={formData.currency === "INR"}
                                  onChange={handleChange}
                                  className="currency-radio"
                                />
                                <label htmlFor="inr" className="currency-label ps-2">
                                  INR
                                </label>
                              </div>
                            </div>

                            <div className="col-lg-7 order-lg-1">
                              <select
                                id="registrationFee"
                                name="registrationFee"
                                value={formData.registrationFee}
                                onChange={handleChange}
                                required
                                className="registration-select mb-3"
                              >
                                <option selected disabled value="">
                                  Select Currency & Amount
                                </option>
                              </select>
                            </div>
                          </div>
                          <button type="submit">Submit</button>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* _______________________________________________________________________________________________________________________________________________ */}

            <br />
            <h4 style={{ textShadow: "0.5px 0.5px", fontWeight: "bold" }} className="text-left mt-3">
              &nbsp; Our Features
            </h4>
            <div className="container mt-3">
              <p className="lh-lg">
                We offer various features to help our users know the paraphrased technical sentences | | tortured phrases generated by using Thesaurus
                or AI tools, check whether or not an image is original | | AI-Generated | | Copied, from which original image it has been generated
                from, and which regions of the image have been used without citing the original research article.
              </p>
            </div>
          </div>
          <br />
          <div className="container">
            <div className="row">
              <div className="col">
                <h5 style={{ textShadow: "0.5px 0.5px", fontWeight: "bold" }} className="text-left mt-3">
                  &nbsp; TP Detector: You now know the quality of your research
                </h5>
                <br />
                <div>
                  <Row className="pt-2">
                    <Col sm={12} md={6} lg={6}>
                      <Card>
                        <Card.Img variant="top" src={Img1} height={`300`} />
                        <Card.Body>
                          <Card.Title
                            style={{
                              textShadow: "0.4px 0.4px",
                              fontWeight: "bold",
                              fontSize: "1.2rem",
                            }}
                          >
                            Protect your research from Rejection/Retraction
                          </Card.Title>
                          <Card.Text className="lh-lg">
                            Proofread your article from the potential for any publisher penalizing AI content and only publish the research content
                            you know the true Originality of.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                      <Card>
                        <Card.Img variant="top" src={Img2} height={`280`} />
                        <Card.Body>
                          <Card.Title
                            style={{
                              textShadow: "0.4px 0.4px",
                              fontWeight: "bold",
                              fontSize: "1.2rem",
                            }}
                          >
                            Detect whether the content is AI written
                          </Card.Title>
                          <Card.Text className="lh-lg">
                            With all tortured phrases and Research results saved in one place. You will no longer required to rely on the publisher
                            side quality check or waste time by manually proof-reading it by yourself.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="pt-2">
                    <Col sm={12} md={6} lg={6}>
                      <Card>
                        <Card.Img variant="top" src={Img3} height={`280`} />
                        <Card.Body>
                          <Card.Title
                            style={{
                              textShadow: "0.4px 0.4px",
                              fontWeight: "bold",
                              fontSize: "1.2rem",
                            }}
                          >
                            Full Research Analyzer
                          </Card.Title>
                          <Card.Text className="lh-lg">
                            Scan your entire research article/theme/result output images to understand the risk of publishing the article that has the
                            potential of getting flagged as a problematic paper by publisher even after the publication.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={12} md={6} lg={6}>
                      <Card>
                        <Card.Img variant="top" src={Img4} height={`280`} />
                        <Card.Body>
                          <Card.Title
                            style={{
                              textShadow: "0.4px 0.4px",
                              fontWeight: "bold",
                              fontSize: "1.2rem",
                            }}
                          >
                            Rank better in Research
                          </Card.Title>
                          <Card.Text className="lh-lg">
                            In various scholarly indexes and engine using our best-in-class research novelty and quality detection feature ensures you
                            to produce a high-quality research content and gain more citations.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <br />
          <br />
          <div
            className="mx-auto d-table p-2 rounded"
            style={{
              fontSize: "16px",

              textAlign: "center",
              backgroundColor: "white",
            }}
          >
            <p>
              Total Number of Visits=
              <div
                id="sfc9h1ylcej2mamhmcwhm3ya6jxsspgx1zd"
                style={{
                  display: "inline-block",
                  border: "1px solid #ccc",
                  padding: "2px",
                  margin: "0 5px",
                }}
              ></div>
            </p>
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default Home;
