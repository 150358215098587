import React, { useState } from "react";
import react from "react";

import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Navbar, Nav, Image } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Row, Col } from "react-bootstrap";
import image from "./lens.webp";
import TP from "./TPdetector_logo.png";
import Right from "./Right-img.png";
import Img1 from "./20638.jpg";
import Img2 from "./Img-2.jpg";
import Img3 from "./Img-3.jpg";
import Img4 from "./Img4.jpg";
import Ejesra from "./eJESRA-icon.png";

import "./contact.css";
const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    comment: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const navBarStyle = {
    background: "#D9FDFF",
    color: "#000000",
    padding: "10px",
  };

  const navbarStyle = {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "light",
    color: "black",
    padding: "10px",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      try {
        // Assuming you are using fetch API to send form data to the backend
        const response = await fetch(process.env.REACT_APP_API_URL + "/Contactus", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          alert("Form submitted successfully");
          // Reset form data after successful submission
          setFormData({
            name: "",
            email: "",
            comment: "",
          });
        } else {
          // Handle server errors
          const errorData = await response.json();
          console.error("Server Error:", errorData);
          alert("Failed to submit form. Please try again later.");
        }
      } catch (error) {
        console.error("Error:", error);
        alert("An unexpected error occurred. Please try again later.");
      }
    } else {
      setErrors(errors);
    }
  };

  const validateForm = (data) => {
    let errors = {};

    if (!data.name.trim()) {
      errors.name = "Name is required";
    } else if (data.name.trim().length > 15) {
      errors.name = "Should not be more than 15 characters";
    }

    if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email address is invalid";
    }

    if (!data.comment.trim()) {
      errors.comment = "Comment is required";
    } else if (data.comment.trim().split(/\s+/).length > 1000) {
      errors.comment = "Comment should not exceed 1000 words";
    }

    return errors;
  };

  return (
    <div className="container">
      <Navbar expand="lg" style={navbarStyle}>
        <Container>
          <Navbar.Brand href="./">
            <Image src={TP} alt="TP Detector Logo" style={{ width: "70px", height: "auto" }} className="display-3" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto me-2">
              <Nav.Link href="./SignUp" className="">
                Sign-Up
              </Nav.Link>
              <Nav.Link href="/Login" className="gx-5">
                Login
              </Nav.Link>
              <Nav.Link href="/SubmitPaper" className="gx-5">
                Submit Paper
              </Nav.Link>

              <Nav.Link href="/Contact" className="gx-5">
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <h1>Contact US</h1>
          <div className="input-group">
            <label htmlFor="name">Name</label>
            <input id="name" name="name" type="text" value={formData.name} onChange={handleChange} required />
            {errors.name && <p className="error">{errors.name}</p>}
          </div>
          <div className="input-group">
            <label htmlFor="email">Email address</label>
            <input id="email" name="email" type="email" value={formData.email} onChange={handleChange} required />
            {errors.email && <p className="error">{errors.email}</p>}
          </div>
          <div className="input-group">
            <label htmlFor="comment">Message</label>
            <textarea id="comment" name="comment" rows={5} value={formData.comment} onChange={handleChange} required />
            {errors.comment && <p className="error">{errors.comment}</p>}
          </div>
          <div className="button-container">
            <button type="submit">Send</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
