import react from "react";
import { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import Card from "react-bootstrap/Card";
import axios from "axios";
import { Link } from "react-router-dom";
import { Navbar, Nav, Image } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import TP from "./TPdetector_logo.png";

import "./App.css"
const SubmitPaper = () => {
  
    const containerStyle = {textAlign: 'center', padding: '20px',};
      const headingStyle = {fontSize: '2rem', margin: '20px 0'};
      const payTableTd = {padding:  '.2rem 1rem', border: '1px solid #0006'};
      const payStyle = {fontSize: '1.5rem', fontWeight: 'bold'};
      // const priceStyle = {fontSize: '1rem'};
      const linkStyle = {backgroundColor: '#007bff', color: 'white', padding: '10px 20px', borderRadius: '5px', textDecoration: 'none', width: '300px', marginInline: "1rem" };
      const styles = {body: {fontFamily: '"Poppins", sans-serif', margin: 0, padding: 0, }, invoiceForm: { fontFamily: '"Poppins", sans-serif', }, popupContainer: { display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      fontSize: "0.9rem",
      zIndex: 100,
    },
    popup: {
    position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.43)", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 100, overflowY: "auto", },
    popupContent: {
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "8px",
      width: "90%",
      maxWidth: "980px",
      marginBlock: "2rem",
    },
    close: {
      position: "absolute",
      top: "10px",
      right: "10px",
      fontSize: "24px",
      color: "#888",
      cursor: "pointer",
      borderRadius: "20%",
      padding: "7px",
    },
    closeHover: {color: "#333",},
    h2: {marginBottom: "20px",},
    formGroup: {marginBottom: "20px",},
    label: {display: "block",marginBottom: "5px",},
    input: {width: "100%",padding: "10px",borderRadius: "5px",border: "1px solid #ccc",transition: "border-color 0.3s ease",boxSizing: "border-box",},
    inputFocus: {borderColor: "#007bff",},registrationFeeInput: {display: "flex",alignItems: "center",},registrationFeeInputSelect: {marginLeft: "10px",padding: "9px",},
    button: {padding: "10px 20px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
      margin: "auto",
      display: "table",
    },
    buttonHover: {backgroundColor: "#0056b3",margin: "auto",display: "table",},
    currencyContainer: {display: "flex",justifyContent: "space-around",},
    currencyRadio: {display: "inline-block",},
    currencyLabel: {display: "inline-block",marginLeft: "5px",},
    mediaQuery: {"@media (max-width: 991px)": {popup: {
          // display: 'table',
        },
        popupContent: {
          margin: "auto",
        },
      },
    },
  };
  
  // const navBarStyle = {background: "#D9FDFF",color: "#000000",padding: "10px",};

  const navbarStyle = {display: "flex", justifyContent: "flex-end", backgroundColor: "light", color: "black", padding: "10px",};


  return (
    <div className="container">
             {" "}
          <br />
          <Navbar expand="lg" style={navbarStyle}>
            <Container>
              <Navbar.Brand href="./">
                <Image
                  src={TP}
                  alt="TP Detector Logo"
                  style={{ width: "70px", height: "auto" }}
                  className="display-3"
                />
              </Navbar.Brand>

              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto me-2">
                  <Nav.Link href="./" className="">
                    Home
                  </Nav.Link>
                  <Nav.Link href="./SignUp" className="">
                    Sign-Up
                  </Nav.Link>
                  <Nav.Link href="/Login" className="gx-5">
                    Login
                  </Nav.Link>
                  <Nav.Link href="/SubmitPaper" className="gx-5">
                    Submit Paper
                  </Nav.Link>

                  <Nav.Link href="/Contact" className="gx-5">
                    Contact Us
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
              <div style={containerStyle}>
                <h1 style={headingStyle}>Submit Paper</h1>

                <div className="text-end">
                </div>
                <h4 style={payStyle} className="pay mt-3">
                  Buy Credits
                </h4>
                
                  <table className='mx-auto mb-5' >
                  <tr><td style={payTableTd}>$1</td>  <td style={payTableTd}>5,000 Words</td> </tr>
                  <tr><td style={payTableTd}>$2</td>  <td style={payTableTd}>10,000 words</td></tr> 
                  <tr><td style={payTableTd}>$3</td>  <td style={payTableTd}>15,000 words</td> </tr>
                  <tr><td style={payTableTd}>$4</td>  <td style={payTableTd}>20,000 words</td> </tr>
                  <tr><td style={payTableTd}>$5</td> <td style={payTableTd}>25,000 words</td> </tr>
                  </table>
                  <p>


        <a href="https://www.paypal.com/paypalme/InventiveResOrg" style={linkStyle} target="_blank" >PayPal - <span className="text-success bg-warning p-1 rounded shadow">USD</span></a>
        
        {/* <a href="https://razorpay.com/payment-button/pl_Nd0w4P5RP22rdr/view" style={linkStyle} target="_blank">RazorPay - <span className="text-success bg-warning p-1 rounded shadow">INR</span></a> */}
        </p>
        {/* <div><form><script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_Mv5Y7VghyOwsxB" async> </script> </form></div> */}

      <div  className="my-5">
      <p>After the payment, login credentials as well as instructions for obtaining the TP Report will be shared.</p>
      <p>TP Report contains the Tortured Phrases or poorly-written phrases within the article.</p>
      <p>more than 50,000 words or publishers email us @ <a href="mailto:info@tpdetector.com">info@tpdetector.com</a> for annual subscription.</p>
      </div>
      <Button href="/Login" className="bg-secondary text-light p-1 border-dark border-2">Login</Button>
      </div>
     </div>
  );
};

export default SubmitPaper;

