import React, { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { Navbar, Nav, Image } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import TP from "./TPdetector_logo.png";

import "./SignUp.css";

const SignUp = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!firstName) {
      alert("Firstname is required.");

      return;
    }

    if (!lastName) {
      alert("Lastname is required.");
      return;
    }

    if (!email) {
      alert("E-mail Address is required.");
      return;
    }

    if (password.length < 8 || !/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      alert("Password must be at least 8 characters long and include special characters.");
      return;
    }

    const formData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
    };

    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + "/signup", formData);

      alert("Form submitted successfully!");

      setFirstName(" ");
      setLastName(" ");
      setEmail("");
      setPassword(" ");
      setShowPassword(" ");

      console.log(response.data);
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };

  const containerStyle = {
    textAlign: "center",
    padding: "20px",
  };

  const headingStyle = {
    fontSize: "2rem",
    margin: "20px 0",
  };
  const payTableTd = {
    padding: ".2rem 1rem",
    border: "1px solid #0006",
  };
  const payStyle = {
    fontSize: "1.5rem",
    fontWeight: "bold",
  };

  const priceStyle = {
    fontSize: "1rem",
  };

  const linkStyle = {
    backgroundColor: "#007bff",
    color: "white",
    padding: "10px 20px",
    borderRadius: "5px",
    textDecoration: "none",
    width: "300px",
    marginInline: "1rem",
  };

  const styles = {
    body: {
      fontFamily: '"Poppins", sans-serif',
      margin: 0,
      padding: 0,
    },
    invoiceForm: {
      fontFamily: '"Poppins", sans-serif',
    },
    popupContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      fontSize: "0.9rem",
      zIndex: 100,
    },
    popup: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.43)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 100,
      overflowY: "auto",
    },
    popupContent: {
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "8px",
      width: "90%",
      maxWidth: "980px",
      marginBlock: "2rem",
    },
    close: {
      position: "absolute",
      top: "10px",
      right: "10px",
      fontSize: "24px",
      color: "#888",
      cursor: "pointer",
      borderRadius: "20%",
      padding: "7px",
    },
    closeHover: {
      color: "#333",
    },
    h2: {
      marginBottom: "20px",
    },
    formGroup: {
      marginBottom: "20px",
    },
    label: {
      display: "block",
      marginBottom: "5px",
    },
    input: {
      width: "100%",
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      transition: "border-color 0.3s ease",
      boxSizing: "border-box",
    },
    inputFocus: {
      borderColor: "#007bff",
    },
    registrationFeeInput: {
      display: "flex",
      alignItems: "center",
    },
    registrationFeeInputSelect: {
      marginLeft: "10px",
      padding: "9px",
    },
    button: {
      padding: "10px 20px",
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
      margin: "auto",
      display: "table",
    },
    buttonHover: {
      backgroundColor: "#0056b3",
      margin: "auto",
      display: "table",
    },
    currencyContainer: {
      display: "flex",
      justifyContent: "space-around",
    },
    currencyRadio: {
      display: "inline-block",
    },
    currencyLabel: {
      display: "inline-block",
      marginLeft: "5px",
    },
    mediaQuery: {
      "@media (max-width: 991px)": {
        popup: {
          // display: 'table',
        },
        popupContent: {
          margin: "auto",
        },
      },
    },
  };

  const navBarStyle = {
    background: "#D9FDFF",
    color: "#000000",
    padding: "10px",
  };

  const navbarStyle = {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "light",
    color: "black",
    padding: "10px",
  };

  return (
    <div>
      <Navbar expand="lg" style={navbarStyle}>
        <Container>
          <Navbar.Brand href="./">
            <Image src={TP} alt="TP Detector Logo" style={{ width: "70px", height: "auto" }} className="display-3" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto me-2">
              <Nav.Link href="./" className="">
                Home
              </Nav.Link>
              <Nav.Link href="./SignUp" className="">
                Sign-Up
              </Nav.Link>
              <Nav.Link href="/Login" className="gx-5">
                Login
              </Nav.Link>
              <Nav.Link href="/SubmitPaper" className="gx-5">
                Submit Paper
              </Nav.Link>

              <Nav.Link href="/Contact" className="gx-5">
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="sign-up-container">
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formFirstName">
            <Form.Label>Firstname</Form.Label>
            <Form.Control type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
          </Form.Group>
          <Form.Group controlId="formLastName">
            <Form.Label>Lastname</Form.Label>
            <Form.Control type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>E-mail Address</Form.Label>
            <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </Form.Group>
          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type={showPassword ? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value)} required />
          </Form.Group>
          <Form.Group controlId="formShowPassword">
            <Form.Check type="checkbox" label="Show password" checked={showPassword} onChange={(e) => setShowPassword(e.target.checked)} />
          </Form.Group>
          <br /> <br />
          <span className="border border p-3 rounded">
            Already have an account? &nbsp;
            <Button href="/Login" className="bg-success text-white p-1">
              Log in here
            </Button>
          </span>
          <br />
          <div className="d-flex justify-content-center pt-4">
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SignUp;
