import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./Component/Dashboard";
import FileUpload from "./fileupload";
import Login from "./login";

import Home from './Home';
import SignUp from "./SignUp";
import SubmitPaper from "./Submitpaper";
import Contact from "./contact";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path="/Login" element={<Login />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/SubmitPaper" element={<SubmitPaper />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/FileUpload" element={<FileUpload />} />
        
        
      </Routes>
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
